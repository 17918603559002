import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCatalog,
  selectUndCat,
  selectCatalog,
  selectScroll,
  selectActSubCat,
} from "../../../redux/selectors";
import {
  catalogActReducer,
  getCatalog,
  subCatalAct,
} from "../../../redux/slices/catalogSlice";
import { goScroll } from "../../../redux/slices/navigateSlice";
import s from "./catalog.module.css";
import UnderCatalog from "./UnderCatalog";
import pic0 from "../../../image/0.png";
import pic1 from "../../../image/1.png";
import pic2 from "../../../image/2.png";
import pic3 from "../../../image/3.png";
import pic4 from "../../../image/4.png";
import pic5 from "../../../image/5.png";
import pic6 from "../../../image/6.jpg";

import pic8 from "../../../image/9.png";
export default function Catalog() {
  const catalog = useSelector(selectCatalog);
  const underCatalog = useSelector(selectUndCat);
  const activeCatalog = useSelector(selectCurrentCatalog);
  const dispatch = useDispatch();
  const actSubCat = useSelector(selectActSubCat)
  const pics = [pic0, pic1, pic2, pic3, pic4, pic5, pic6,'', pic8];
  const scroll = useSelector(selectScroll);
  const refCatalog = useRef(null);

  useEffect(() => {
    if (scroll.catalog) {
      refCatalog.current.scrollIntoView({ behavior: "smooth" });
      dispatch(goScroll({ ...scroll, catalog: false }));
    }
  }, [scroll, dispatch]);

  useEffect(() => {
    dispatch(getCatalog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyCatalog = underCatalog.map((el) => {
    if (el[0].subCatalog[0][1]) {
      return true;
    }
    return false;
  });
  const emptyUndCat = underCatalog.map((el) => {
    if (el[0].name) {
      return true;
    }
    return false;
  });

  const onClick = (e, index) => {
    refCatalog.current.scrollIntoView({ behavior: "smooth" });

    if (index === activeCatalog[0]) {
      dispatch(catalogActReducer([null, 0, 0]));
    } else {
      if (emptyUndCat[index]) {
        console.log(index);
        dispatch(catalogActReducer([index, 0, 0]));
      } else {
        dispatch(catalogActReducer([index, 0, 0]));
        dispatch(subCatalAct([true, Number(e.pageY) - 2700]));
      }
    }
  };

  const takeCatalog = catalog.map((value, index) => {
    return (
      <div
        style={!emptyCatalog[index] ? { opacity: "0.3" } : { opacity: "1" }}
        key={index + "cat"}
        className={s.catalogBlockMain}
      >
        <div className={s.catalogList}>
          <div className={s.catalogBox}>
            <div className={s.catalogBlockNumber}>0{index + 1}</div>
            <div style={actSubCat[0] ? { opacity: '0.3', cursor: 'default' } : { opacity: "1" }}
              className={
                !emptyCatalog[index]
                  ? s.catalogBlockBigTextDisact
                  : s.catalogBlockBigText
              }
              onClick={
                emptyCatalog[index]
                  ? (e) => onClick(e, index)
                  : () => {
                    return;
                  }
              }
            >
              {value}
              {pics[index] && value && (
                <img className={s.catalogPic} src={pics[index]} alt="" />
              )}
            </div>
          </div>
          {activeCatalog[0] === index && <UnderCatalog />}
        </div>
        <div className={s.line}></div>
      </div>
    );
  });

  return (
    <div ref={refCatalog} className={s.pageCatalog}>
      <div className={s.container}>
        <div className={s.catalog}>
          <h2 className={s.catalogTitle}>Каталог</h2>
          <div className={s.catalogblock}>{takeCatalog}</div>
        </div>
      </div>
    </div>
  );
}
