import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./technologyCardLora.module.css";
import Futter from "../futter/Futter";
import CBKX_1_LoRa from "../../image/CBKX_1_LoRa.png";
import CBKG_1_LoRa from "../../image/CBKG_1_LoRa.png";
import PulsarU from "../../image/PulsarU.png";
import LoRa_M from "../../image/LoRa_M.png";
import LoRa_U from "../../image/LoRa_U.png";
import Schetchik_imp_Lora from "../../image/Schetchik_imp_Lora.png";
import BS_Vega_1kh from "../../image/BS_Vega_1.png";
import Antenna_868 from "../../image/Antenna_868.png";
import Mult from "./Mult";
import { useNavigate } from "react-router-dom";
import { catalogActReducer, changeCurrProd } from "../../redux/slices/catalogSlice";
import { selectStart } from "../../redux/selectors";
import { startMult } from "../../redux/slices/navigateSlice";
import { HiOutlineChevronRight } from "react-icons/hi2";

const TechnologyCardLora = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const start = useSelector(selectStart)

  const prod = [['Счетчик холодной воды крыльчатый СВКХ-15к c радиомодулем LoRa', 'СВКХ-15к с радиомодулем LoRa',
  CBKX_1_LoRa, [0, 0, 0]],
  ['Счетчик горячей воды крыльчатый СВКГ-15к c радиомодулем LoRa', 'СВКГ-15к с радиомодулем LoRa',
  CBKG_1_LoRa, [0, 0, 0]],
  ['Счетчик воды ультразвуковой Пульсар У', 'Пульсар У DN15',
  PulsarU, [0, 0, 1]],
  ['Теплосчетчик механический Экохит М с интерфейсом LoRa', '«Экохит М» с радиомодулем LoRa DN15',
  LoRa_M, [1, 0, 0]],
  ['Теплосчетчик ультразвуковой Экохит У с интерфейсом LoRa', 'Экохит У с радиомодулем LoRa DN15',
  LoRa_U, [1, 1, 0]],
  ['Счетчик импульсов-регистратор Пульсар 3-канальный с LoRa', 'Счетчик импульсов-регистратор Пульсар 3-канальный с радиоинтерфейсом LoRa',
  Schetchik_imp_Lora, [4, 0, 0]],
  ['Базовая станция Вега БС-2.2 *', 'Базовая станция',
  BS_Vega_1kh, [5, 0, 0]],
  ['Антенна 868-01-А10 для базовой станции LoRaWAN', 'Антенна для базовой станции LoRaWAN',
  Antenna_868, [5, 0, 0]],
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    setTimeout(() => {
      if (start[0]) {
        let a = [...start]
        a.splice(0, 1, false)
        dispatch(startMult(a))
      }
    }, 7000)
  }, [dispatch, start]);
  const onClick = (catalog, product) => {

    dispatch(catalogActReducer(catalog))
    dispatch(changeCurrProd(product))
    navigate('/product')
  }
  const products = prod.map((el, i) => {
    return (
      <div onClick={() => {
        onClick(el[3], el[1])
      }} key={el[1]} className={s.blockCatalog}>
        <p className={s.numberPoductCatalog}>{'0' + (i + 1)}</p>
        <p className={s.poductCatalog}>
          {el[0]}
        </p>
        <img className={s.svkh} src={el[2]} alt="" />
      </div>
    )
  })



  return (


    <div>
        {(start[1] && !start[1]) ? <Mult close={() => {       // убрать !start[1]
        let a = [...start]
        a.splice(0, 1, false);
        dispatch(startMult(a))
      }} name={'picStartLora'} /> :
        <div className={s.technologyCard}>
          <div className={s.container}>
            <div className={s.blockTree}>
              <span className={s.item} onClick={() => navigate(-1)}>Технологии</span>
              <HiOutlineChevronRight />
               <span className={s.item}>LoRa</span>
            </div>

            <h2>
              LoRa — решение для{" "}
              <span className={s.text11}>дистанционного учета</span> воды и тепла
            </h2>

            <div className={s.blockTop}>
              <Mult name={'picLora'} />
            </div>

            <h3 className={s.hArhitect}>Архитектура сети LoRa</h3>
            <p className={s.textArhitect}>
              Сбор и передача данных с помощью технологии LoRa включает следующие
              элементы:
            </p>
            <div className={s.blockArhitect}>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Конечные устройства</p>
                <p className={s.textRight}>
                  Счетчики энергоресурсов (счетчики воды, счетчики тепла) со
                  встроенными модулями LoRa. Такие устройства работают автономно,
                  от батареек. Датчики собирают данные и затем передают их на
                  шлюз.
                </p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Шлюз</p>
                <p className={s.textRight}>
                  Базовая станция, которая получает данные от конечных узлов
                  посредством радиоканала и передает данные в транзитную сеть
                  (например, Ethernet, сотовые сети и т. д.). Базовая станция
                  обменивается данными с модулями LoRa по протоколу LoRaWAN и
                  через Интернет с сетевым сервером. Основная задача этого
                  элемента системы — собрать данные со всех доступных модулей и
                  передать их на сервер.
                </p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сетевой сервер </p>
                <p className={s.textRight}>
                  Это компонент сетевой инфраструктуры, который обрабатывает и
                  хранит данные, передаваемые конечными устройствами и шлюзами
                  LoRa. Сервер может быть размещен в облаке или в локальной сети,
                  и обычно на нем работает специализированное программное
                  обеспечение, которое позволяет ему получать, декодировать и
                  обрабатывать данные, передаваемые по сети LoRa.
                </p>
              </div>
              <div className={s.listArhitect}>
                <p className={s.textLeft}>Сервер приложений</p>
                <p className={s.textRight}>
                  Сервер приложений взаимодействует с конечными устройствами,
                  отслеживает их состояние и контролирует работу. Является
                  конечной точкой сбора данных.{" "}
                </p>
              </div>
            </div>
            <h3 className={s.hTehnology}>
              Предлагаемые компоненты LoRa системы:
            </h3>
            <div className={s.listCatalog}>
              {products}

            </div>

            <p className={s.textTarif}>
              {" "}
              * Для организации сбора данных с приборов учета с дистанционным
              съемом ООО "ПремексБел" рекомендует приобрести: SIM-карту мобильного
              оператора МТС с тарифным планом "Интернет вещей" либо SIM-карту
              мобильного оператора А1 с тарифным планом "Телеметрия NBiot"
            </p>
            <p className={s.textSlogan}>
              Свяжитесь с нами для получения предложения или информации!
            </p>
          </div>
        </div>
      }

      {!start[0] && <Futter />}
    </div>
  );
};

export default TechnologyCardLora;
